@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: rgb(243 244 246 / var(--tw-bg-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(35 36 72);
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(35 36 72);
}

#root {
  height: 100%;
}

@layer base {
  h1 {
    @apply text-2xl font-bold mb-4;
  }
  h2 {
    @apply text-xl font-semibold mt-6 mb-2;
  }
  p {
    @apply mb-4;
  }
  ul {
    @apply list-disc pl-6 mb-4;
  }
}

@layer utilities {
  .scrollbar-thin::-webkit-scrollbar {
    width: 6px;
  }
  .scrollbar-thin::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }
  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.pro-tip {
  @apply bg-purple-100 border-l-4 border-purple-500 p-4 my-4;
}

/* global.css */
.main-header {
  width: 100%;
  position: fixed;
  top:0;
  z-index: 99;
}

aside.sidebar {
  position: fixed;
  top:-20px;
}

aside.sidebar:not(:last-child) {
  margin-bottom: 0px;
}

.course-content {
  max-width: 800px;
  margin: 64px auto 0;
  padding: 0 1em;
  font-family: Arial, sans-serif;
}

/* Typography */
.course-title {
  font-size: 2rem;
  color: #323232;
  margin-bottom: 1rem;
  line-height: 1.4em;
  font-weight: 700;
}

.course-intro {
  font-size: 1.1rem; /* Increased for better readability */
  line-height: 1.8;
  color: #555;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1.4rem; /* Slightly reduced for balance */
  color: #1d1e1f;
  margin-bottom: 0.5rem;
  margin-top: 2em;
  padding-bottom: 0.5rem;
}

.subsection-title {
  font-size: 1.4rem;
  color: #2c3e50;
  margin-top: 2rem; /* Increased spacing for clarity */
  margin-bottom: 0.75rem;
  font-weight: 600;
}

/* Lists */
.bullet-list {
  list-style-type: disc;
  padding-left: 2.5rem; /* Increased padding for better alignment */
  margin-bottom: 1.5rem;
}

.bullet-list li {
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  color:#333;
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.nav-button {
  padding: 0.75rem 1.5rem; /* Increased padding for better click area */
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.nav-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.nav-button:active {
  transform: translateY(0);
}

.nav-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Example Answer */
.example-answer {
  font-style: italic;
  margin-left: 20px;
  padding: 12px;
  background-color: #f9f9f9;
  border-left: 4px solid #6c63ff;
  border-radius: 4px;
}

h4.example-heading {
    font-size: 1.2em;
    font-weight: 600;
}1234


/* Question Type Icon */
.question-type-icon {
  margin-right: 10px;
  font-size: 24px;
  color: #6c63ff;
}

/* Collapsible Sections */
.collapsible-section {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 25px;
  overflow: hidden; /* Ensures content doesn't spill out */
}

.collapsible-header {
  background-color: #f8f8f8;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapsible-header:hover {
  background-color: #ececec;
}

.collapsible-content {
  padding: 16px;
  background-color: #ffffff;
  display: none; /* Hidden by default, toggle with JavaScript */
}

.collapsible-section.active .collapsible-content {
  display: block;
}

/* Framework Steps */
.framework-step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.step-icon {
  font-size: 24px;
  margin-right: 15px;
  color: #6c63ff;
}

.step-content {
  flex: 1;
}

.framework-step:nth-child(even) .step-content {
  background-color: #f8f8f8; /* Alternating background for better differentiation */
  padding: 10px;
  border-radius: 4px;
}

/* Sample Questions */
.sample-questions {
  background-color: #f0f8ff; /* Lighter background for distinction */
  border-left: 4px solid #6c63ff;
  padding: 15px;
  margin-top: 15px;
  border-radius: 4px;
}

/* Pro Tip */
.pro-tip {
  background-color: #fff1eb;
  border: 1px solid #ff9c91;
  border-radius: 6px;
  padding: 16px;
  margin-top: 20px;
  position: relative;
}

.pro-tip::before {
  content: "💡"; /* Light bulb icon */
  position: absolute;
  top: -16px;
  left: 16px;
  font-size: 1.5rem;
}

.pro-tip-title {
  font-weight: 700;
  color: #1890ff;
  margin-left: 40px; /* Space for the icon */
  margin-bottom: 8px;
}

/* Course Conclusion */
.course-conclusion {
  font-size: 1.15rem; /* Slightly larger for emphasis */
  font-weight: 500;
  margin-top: 35px;
  padding: 25px;
  background-color: #f6f6f6;
  border-radius: 6px;
  border-left: 4px solid #232448; /* Added accent */
}

/* Glossary */
.glossary-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 35px;
}

.glossary-item {
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.glossary-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.glossary-term {
  color: #2c3e50;
  font-size: 1.25em;
  margin-bottom: 10px;
  border-bottom: 2px solid #6c63ff;
  padding-bottom: 5px;
}

.glossary-definition {
  margin-bottom: 10px;
  font-size: 1rem;
}

.glossary-example {
  font-style: italic;
  color: #555;
  font-size: 0.95rem;
}

/* Intro Section */
.intro-section {
  background-color: #f0f8ff;
  border-left: 5px solid #6c63ff;
  padding: 25px;
  margin-bottom: 35px;
  border-radius: 6px;
}

/* Answer Quality */
.answer-quality {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 25px;
}

.answer-quality h3 {
  color: #6c63ff;
  margin-bottom: 12px;
  font-size: 1.3rem;
}

/* Numbered List */
.numbered-list {
  list-style-type: decimal;
  padding-left: 2.5rem;
  margin-bottom: 20px;
}

.numbered-list li {
  margin-bottom: 12px;
  font-size: 1rem;
}

/* Estimation Step */
.estimation-step {
  background-color: #f8f8f8;
  border-left: 5px solid #6c63ff;
  padding: 25px;
  margin-bottom: 35px;
  border-radius: 6px;
}

.estimation-step h3 {
  color: #6c63ff;
  margin-bottom: 12px;
  font-size: 1.3rem;
}

.estimation-example {
  background-color: #f0f8ff;
  border-radius: 6px;
  padding: 20px;
  margin-top: 20px;
  border-left: 3px solid #6c63ff;
}

.estimation-example h3 {
  color: #2c3e50;
  margin-top: 18px;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

/* Conclusion Section */
.conclusion-section {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 6px;
  padding: 25px;
  margin-top: 35px;
}

/* Course Content */
.course-content h3 {
  margin-top: 35px;
  margin-bottom: 18px;
  color: #2c3e50;
  font-size: 1.4rem;
}

.course-content p {
  margin-bottom: 18px;
  font-size: 1.1rem;
  color:#333;
  line-height: 1.6;
}

.course-content ul.bullet-list {
  margin-bottom: 25px;
}

.course-content .strategy-section {
  margin-bottom: 45px;
}

.course-content .conclusion-section {
  background-color: #f0f8ff;
  padding: 25px;
  border-radius: 8px;
  margin-top: 45px;
  border-left: 4px solid #6c63ff;
}

/* Profile styles */
.profile-initial {
  background-color: #4f46e5;
  color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: bold;
}

.profile-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.profile-item-icon {
  margin-right: 1rem;
  color: #6b7280;
}

.profile-item-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
}

.profile-item-value {
  font-size: 1rem;
  font-weight: 500;
  color: #111827;
}


/* Responsive Design */
@media (max-width: 768px) {
  .navigation-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .nav-button {
    width: 100%;
    text-align: center;
  }

  .glossary-container {
    grid-template-columns: 1fr;
  }

  .framework-step {
    flex-direction: column;
    align-items: flex-start;
  }

  .step-icon {
    margin-bottom: 10px;
  }
}

@media (min-width:768px) {
  .course-content {
    margin-left:300px;
  }
}
